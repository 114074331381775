import type { MenuResponse } from './untappd-types';
import { BoardItem } from './types';
import { getTagsFromName } from './utils';

import config from './venues';

const untappdApi = <T = any>(url: string) => {
  const TOKEN = window.btoa(`${process.env.NEXT_PUBLIC_API_USER}:${process.env.NEXT_PUBLIC_API_KEY}`);
  return window
    .fetch(`https://business.untappd.com/api/v1/${url}`, {
      headers: { Authorization: `Basic ${TOKEN}` },
    })
    .then((res) => res.json() as Promise<T>);
};

export const getBeers = async () => {
  const beerJSON = await untappdApi<MenuResponse>(`menus/${config.menuId}?full=true`);

  const items: BoardItem[] = beerJSON.menu.sections
    .map((s) => s.items)
    .flat()
    .map((item) => ({
      id: item.id,
      ...getTagsFromName(item.name),
      tap_number: item.tap_number,
      description: item.description,
      image: item.label_image_hd,
      style: item.style,
      brewery: item.custom_brewery || item.brewery,
      abv: item.abv,
      ibu: item.ibu,
      containers: item.containers.map((c) => ({
        id: c.id,
        price: parseFloat(c.price),
        size: c.container_size?.name || c.name,
      })),
    }));

  return { items };
};
