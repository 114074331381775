type Venue = 'konn' | 'taproom';

interface Config {
  pageSize: number;
  menuId: number;
}

const config: { [key: string]: Config } = {
  konn: {
    pageSize: 10,
    menuId: 133351,
  },
  taproom: {
    pageSize: 8,
    menuId: 60304,
  },
};

let venue = global.window && window.location.hostname.startsWith('konn') ? 'konn' : 'taproom';

const venueOverride = new URLSearchParams(global.window && window.location.search).get('venue');
if (venueOverride) venue = venueOverride;

export default config[venue];
