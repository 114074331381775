import React from 'react';

interface Props {
  text: string;
  color: string;
  small?: boolean;
}

export const Tag = ({ color, text, small }: Props) => (
  <>
    <div className={`tag ${color} ${small && 'small'}`}>{text}</div>
    <style jsx>{`
      .tag {
        display: inline-block;
        border-radius: 24px;
        padding: 0 16px;
        font-size: 40px;
        line-height: 48px;
      }

      @media (min-width: 1079px) and (max-width: 1081px) {
        .tag {
          padding: 0 8px;
          font-size: 20px;
          line-height: 24px;
        }
      }

      .tag.small {
        padding: 2px 8px;
        font-size: 12px;
        line-height: 16px;
        margin-left: 4px;
      }

      .tag.white {
        color: black;
        background-color: white;
      }

      .tag.yellow {
        color: black;
        background-color: #ffe000;
      }

      .tag.peach {
        color: black;
        background-color: #ffbc9f;
      }

      .tag.gray {
        color: black;
        background-color: #bfbfbf;
      }

      .tag.ua {
        color: #ffd700;
        background-color: #0057b7;
      }
    `}</style>
  </>
);
