import React from 'react';
import Head from 'next/head';
import * as Sentry from '@sentry/browser';

import { getBeers } from '../api';
import { Board } from '../components/Board';
import { BoardItem } from '../types';

Sentry.init({ dsn: 'https://f51e6fa13be64b8d98275f038d605fbb@sentry.io/1785085' });

interface State {
  items: BoardItem[] | null;
}

export default class Home extends React.Component {
  state: State = { items: null };

  componentDidMount() {
    this.loadData();

    setInterval(() => this.loadData(), 60000);
  }

  loadData() {
    getBeers().then(({ items }) => this.setState({ items }));
  }

  render() {
    return (
      <div>
        <Head>
          <title>Tap Room</title>
          <meta name="viewport" content="width=1920" />
          <link rel="icon" href="/favicon.ico" />
        </Head>
        {this.state.items ? <Board items={this.state.items} /> : <h1>Loading</h1>}

        <style jsx>{`
          h1 {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        `}</style>
        <style jsx global>{`
          @font-face {
            font-family: 'SuisseIntl-Medium';
            src: url('/SuisseIntl-Medium.woff2') format('woff2');
          }

          * {
            box-sizing: border-box;
          }

          html,
          body {
            margin: 0;
            padding: 0;
            background: black;
            color: white;
            font-family: 'SuisseIntl-Medium', sans-serif;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-feature-settings: 'liga' 1;
            font-feature-settings: 'kern' 1;
          }
        `}</style>
      </div>
    );
  }
}
