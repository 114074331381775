import React from 'react';

import { Tag } from './types';

/**
 * Replace brackets with yellow coloring markup
 */
export const applyColors = (text: string) => (
  <span
    dangerouslySetInnerHTML={{
      __html: text.replace(/{/g, '<span class="yellow">').replace(/}/g, '</span>'),
    }}
  />
);

const customColors: { [key: string]: string } = {
  new: 'yellow',
  erihind: 'yellow',
  '0%': 'peach',
  '0.5%': 'peach',
  'cellar series': 'gray',
  'ukraina heaks': 'ua',
};

export const getTagsFromName = (name: string) => {
  const bracketMatcher = /({{[a-z0-9 %]+}})/gi;
  const matches = name.match(bracketMatcher);
  const cleanName = name.replace(bracketMatcher, '').trim();
  let tags: Tag[] = [];

  if (matches) {
    tags = [...matches].map((m) => {
      const [_, text] = m.match(/([a-z0-9 %]+)/i)!;
      let color = customColors[text.toLowerCase()] || 'white';
      return { color, text };
    });
  }

  return { tags, name: cleanName };
};
